import { combineReducers } from "redux"
import Basket from "./basketReducer"

const basketReducer = combineReducers({
    Basket
})

export default basketReducer


