import React, { Component } from 'react';
import {

  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  
} from "reactstrap"

import ReactCountryFlag from "react-country-flag"

import { connect } from 'react-redux';

import {language} from '../../../redux/actions/language/language'





class languageButton extends Component {
  state = {
    langDropdown: false,
  }




componentDidMount() {




}

WhichLanguageFirst () {
const dil = localStorage.getItem("dil")
  switch(dil) {
    case 'en' : return (  <div> <ReactCountryFlag className="country-flag"   countryCode="us" svg /><span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">English </span>  </div> )
    case 'tr' : return ( <div>  <ReactCountryFlag className="country-flag"   countryCode="tr" svg /><span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">Türkçe </span> </div>  )

  }

}


  handleLangDropdown = () =>
  this.setState({ langDropdown: !this.state.langDropdown })

  render() {
    return (
     
      <Dropdown
      tag="li"
      className="dropdown-language nav-item"
      isOpen={this.state.langDropdown}
      toggle={this.handleLangDropdown}
      data-tour="language"
    >
      <DropdownToggle
        tag="a"
        className="nav-link"
      >
      {this.WhichLanguageFirst()}
      
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          tag="a"
          onClick={e => {this.props.language("en")}}
        >
          <ReactCountryFlag className="country-flag" countryCode="us" svg />
          <span className="ml-1">English</span>
        </DropdownItem>
        <DropdownItem
          tag="a"
             tag="a"
             onClick={e => {this.props.language("tr")}}
        >
          <ReactCountryFlag className="country-flag" countryCode="tr" svg />
          <span className="ml-1">Türkçe</span>
        </DropdownItem>
        <DropdownItem
          tag="a"
        //  onClick={e => {this.props.dispatch(languageEN())}}
        >
          <ReactCountryFlag className="country-flag" countryCode="de" svg />
          <span className="ml-1">German</span>
        </DropdownItem>
        <DropdownItem
          tag="a"
      //    onClick={e => context.switchLanguage("pt")}
        >
          <ReactCountryFlag className="country-flag" countryCode="pt" svg />
          <span className="ml-1">Portuguese</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
          
    );
  }
}


const mapStateToProps = (state) => {

  return {
    status:state
  }
}


export default connect(mapStateToProps,{language})(languageButton);





/*


import {languageEN, languageTR} from '../../../redux/actions/language/language'

onClick={e => {this.props.dispatch(languageTR())}}

function mapDispatchToProps (dispatch) {
  return {actions:bindActionCreators(languageEN,languageTR,dispatch)}
}


export default connect({mapDispatchToProps})(languageButton);

*/

