import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";

import { history } from "../../../history";
import LanguageButton from "../../../components/extra/Language/LanguageButton";
import chip from "../../../assets/img/cpt/chip.PNG";

import { connect } from "react-redux";
import { type } from "jquery";




class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    langDropdown: true,
    shoppingCart: [],
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown });

    Logout(){
      localStorage.removeItem('token')
      localStorage.removeItem('firstname')
      localStorage.removeItem('lastname')

      history.push('/')
    } 

    ThemeColor (){
      var theme= localStorage.getItem("theme")
      theme!=null ? theme=="dark" ? localStorage.setItem("theme", "light" ) : localStorage.setItem("theme", "dark" )  :  localStorage.setItem("theme", "dark" )  
      window.location.reload(false);
    }


  render() {
    const basket = this.props.Basket;
    var theme= localStorage.getItem("theme")
    const UserDropdown = (props) => {
      return (
        <DropdownMenu right>
          <DropdownItem tag="a" onClick={() => history.push("/Profil")}>
            <Icon.User size={14} className="mr-50" />
            <span className="align-middle">{this.props.Language.navbar_menu_edit_profil}</span>
          </DropdownItem>

          <DropdownItem onClick={() =>  this.ThemeColor() } >
            <Icon.RotateCw size={14} className="mr-50" />
      <span className="align-middle">{theme!=null ? theme=="dark" ? "Light Tasarım" : "Dark Tasarım"   :  "Dark Tasarım" }</span>
          </DropdownItem>

          <DropdownItem divider />
          <DropdownItem
            tag="a"
            href="#"
            onClick={() =>this.Logout()}
          >
            <Icon.Power size={14} className="mr-50" />
            <span className="align-middle">{this.props.Language.navbar_menu_logout}</span>
          </DropdownItem>
        </DropdownMenu>
      );
    };

    if (type(basket) == "array") {
      var renderCartItems = basket.map((item,i) => {
        return (
          <div className="cart-item" key={i}>
            <Media
           
              className="p-0"
              onClick={() => history.push("/ecommerce/product-detail")}
            >
              <Media className="text-center pr-0 mr-1" left>
                <img
                  className={`${
                    item.imgClass
                      ? item.imgClass + " cart-item-img"
                      : "cart-item-img"
                  }`}
                  src={chip}
                  width={110}
                  alt="Cart Item"
                />
              </Media>
              <Media className="overflow-hidden pr-1 py-1 pl-0" body>
                <span className="item-title text-truncate text-bold-500 d-block mb-50">
                  <h5> {item.creditPackages.credit_package_name}</h5>
                </span>
                <span className="item-desc font-small-2 text-truncate d-block">
                Açıklama yok / Empty Description
                </span>
                <div className="d-flex justify-content-between align-items-center mt-1">
                  <span className="align-middle d-block">
                    {item.quantity} x {item.creditPackages.credit_package_price}{" "}
                    TL
                  </span>
             
                </div>
              </Media>
            </Media>
          </div>
        );
      });
    }

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <LanguageButton />

        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link position-relative">
            <Icon.ShoppingCart size={21} />
            {type(basket) == "array" ? (
              <Badge pill color="primary" className="badge-up">
                {type(basket) == "array" ? basket.length : 0}
              </Badge>
            ) : null}
          </DropdownToggle>
          <DropdownMenu
            tag="ul"
            right
            className={`dropdown-menu-media dropdown-cart ${
              type(basket) != "array" ? "empty-cart" : ""
            }`}
          >
            <li
              className={`dropdown-menu-header ${
                type(basket) != "array" ?   "d-none" : "d-block"
              }`}
            >
              <div className="dropdown-header m-0">
                <h3 className="white">
                  {type(basket) == "array" ? basket.length : 0} {this.props.Language.text_cart_notification_items}
                </h3>
                <span className="notification-title">{this.props.Language.text_cart_notification_in_your_cart}</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {renderCartItems}
            </PerfectScrollbar>
            <li
              className={`dropdown-menu-footer border-top ${
                type(basket) != "array" ? "d-none" : "d-block"
              }`}
            >
              <div
                className="dropdown-item p-1 text-center text-primary"
                onClick={() => history.push("/CreditCheckout")}
              >
                <Icon.ShoppingCart size={15} />
                <span className="align-middle text-bold-600 ml-50">
                {this.props.Language.text_cart_notification_checkout}
                </span>
              </div>
            </li>
            <li
              className={`empty-cart ${
                type(basket) == "array" ? "d-none" : "d-block"
              } p-2`}
            >
              {this.props.Language.text_cart_notification_empty_cart}
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>


  



        {/*   Notification Kısmı   */}

 
        {/*   Kullanıcı Kısmı   */}
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
            <span className="user-status">{this.props.Language.navbar_menu_Available}</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Language: state.Language.LanguageReducers,
    Basket: state.Basket.Basket.basketData,
  };
};

export default connect(mapStateToProps)(NavbarUser);


/*

210 satıra ekle

       <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="primary" className="badge-up">
              {" "}
              5{" "}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">5 New</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.PlusSquare
                      className="font-medium-5 primary"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="primary media-heading" tag="h6">
                      You have new order!
                    </Media>
                    <p className="notification-text">
                      Are your going to meet me tonight?
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      9 hours ago
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.DownloadCloud
                      className="font-medium-5 success"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="success media-heading" tag="h6">
                      99% Server load
                    </Media>
                    <p className="notification-text">
                      You got new order of goods?
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      5 hours ago
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.AlertTriangle
                      className="font-medium-5 danger"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="danger media-heading" tag="h6">
                      Warning Notification
                    </Media>
                    <p className="notification-text">
                      Server has used 99% of CPU
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Today
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.CheckCircle
                      className="font-medium-5 info"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="info media-heading" tag="h6">
                      Complete the task
                    </Media>
                    <p className="notification-text">
                      One of your task is pending.
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Last week
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.File className="font-medium-5 warning" size={21} />
                  </Media>
                  <Media body>
                    <Media heading className="warning media-heading" tag="h6">
                      Generate monthly report
                    </Media>
                    <p className="notification-text">
                      Reminder to generate monthly report
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Last month
                    </time>
                  </small>
                </Media>
              </div>
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">Read all notifications</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>

*/