import * as actionTypes from '../../actions/basket/actionTypes';
 
 
 const Basket = (state=[],action) => {

    switch(action.type) {
    case actionTypes.ADD_BASKET:{return{basketData:action.payload.data} //Burdaki basketData   action da tanımladıgımız playload daki ile aynı olmalı
    }



      
    default: {
        return state
      }

    }

}



export default Basket


