import * as actionTypes from './actionTypes'





export const language = (language) => {
 
switch(language) {


    case "en" : {
      localStorage.removeItem("dil")
      localStorage.setItem("dil", "en")
        return  dispatch => {
            dispatch({
                type:actionTypes.LANGUAGE_EN,
                playload:{



                    /* Buttons */

                    button_save:"Save",
                    button_sent:"Submit",
                    button_reset:"Clear",
                    button_update:"Update",
                    button_delete:"Delete",
                    button_cancel:"Cancel",

                    button_settings:"Settings",
                    button_download:"Download",
                    button_download_file:"Download File",
                    button_details:"Details",
                    button_cancel_action:"Cancel The Action",
                    button_move_to_archive:"Move To Archive",
                    button_no_cancel:"Cancel",
                    button_yes_send:"Okay, Send",
                    button_confirm:"Yes, Confirm",
                    button_home:"Home",
                    
                    button_order_page:"Order Page",

                    navbar_menu_edit_profil:"Edit Profil",
                    navbar_menu_logout:"Log Out",
                    navbar_menu_Available:"Available",

    

                   /* Files  Global*/
                  
                   text_files_file_no:"Files No",
                   text_files_details_services:"Services",
                   text_files_details_file_upload:"File Upload",


                    /* Files */
    
                    menu_text_dashboard:"Dashboard",
                    menu_text_incoming_files:"Inbox Files",
                    menu_text_outgoing_files:"Sended Files",
                    menu_text_send_file:"Send File",
                    menu_text_special_outgoing_files:"Special File Send",
                    menu_text_files_statistics:"File Statistics",
                    menu_text_arsives:"Arsives",
                
                    /* Customer */
    
                    menu_text_add_reseller:"Add Reseller",
 
      
           
                   
                    /* Credit */
    
                    menu_text_credit_package:"Credit Packages",
                    menu_text_credit_order:"Credit Orders",
    
    


    
                    menu_text_support:"Support",
    
                    /* Menü Title */
    
                    menu_text_title_file:"FILES",
                    menu_text_title_credit:"CREDIT",
                    menu_text_title_support:"SUPPORT",
             
                     /* Login Page */
                  
                    login_page_text_title :"Login",
                    login_page_text_welcome :"Welcome back, please login to your account.",
                    login_page_text_email_placeholder :"Username",
                    login_page_text_password_placeholder :"Password",
                    login_page_text_remmember :"Remember me",
                    login_page_text_registered_button :"Register",
                    login_page_text_login_button :"Login",
                    login_page_text_complete_button :"Complete",
                    login_page_text_loading_button :"Loading...",


                   /* Register Page*/

                   regsiter_page_text_h1_title:"Create Account",
                   regsiter_page_text_description:"A valid RESELLER ID is required to register in the system. If you are working with a reseller, request a link or reseller code from your reseller..",
                   regsiter_page_text_description_ok:"You can complete the registration to the system by filling out the form below. Then you can go to the login page and log in.",
                   register_page_text_register_success:"Your registration is complete, you are being directed to the login page...",
                   regsiter_page_text_regsiter_reseller_success_info :"You can login to the system after filling the form.",
                   regsiter_login_page_text_regsiter_reseller_success_warning :"This Link you received from the reseller is incorrect. Please contact the reseller.",



                   /* Reseller Page (Add Reseller Page) */


                   add_reseller_page_bradcom_navi_title :"Reseller",
                   add_reseller_page_bradcom_navi_pagename :"Add Reseller",
                   add_reseller_page_bradcom_navi_formname :"Add Reseller Form",
                   add_reseller_page_bradcom_form_firstname :"First Name",
                   add_reseller_page_bradcom_form_lastname :"Last Name",
                   add_reseller_page_bradcom_form_email :"Email",
                   add_reseller_page_bradcom_form_password :"Password",
                   add_reseller_page_bradcom_form_phone :"Phone",
                   add_reseller_page_bradcom_form_address :"Address",
                   add_reseller_page_bradcom_form_company :"Company Name",
                   add_reseller_page_bradcom_form_country :"Country",
                   add_reseller_page_bradcom_form_city :"City",


                   add_register_page_bradcom_form_usercode :"Reseller ID",
                   add_register_page_bradcom_form_firstname :"First Name",
                   add_register_page_bradcom_form_lastname :"Last Name",
                   add_register_page_bradcom_form_email :"E-Email",
                   add_register_page_bradcom_form_password :"Password",
                   add_register_page_bradcom_form_phone :"Phone",
                   add_register_page_bradcom_form_company :"Company Name",
                   add_register_page_bradcom_form_address :"Address",
                   add_register_page_bradcom_form_country :"Country",
                   add_register_page_bradcom_form_city :"City",


                   text_profil_old_password:"Old password",
                   text_profil_new_password:"New password",
                   text_profil_confirm_pasword:"Confirm password",


                /* Files Page ( Send File Page) */

                text_send_file_page_bradcom_navi_title:"FILES",
                text_send_file_page_bradcom_navi_pagename:"Send File",
                text_send_file_page_bradcom_navi_formname:"Send File Form",


                 text_send_file_page_prev:"Prev",
                 text_send_file_page_next:"Next",
                 text_send_file_page_button_okay:"Create",

                 text_send_file_page_form_title:"File Send From",
                 text_send_file_page_service_type:"Service Select",
                 text_send_file_page_service_stepone_info_box_title:"Service İnfo",
                 text_send_file_page_service_stepone_info_box_description:"Each service you select above has its own loan amount. You can check your current credit and the credit amount of the service you want to receive from the right.",
                 text_send_file_page_credit_total_title:"Credit Calculation",
                 text_send_file_page_current_credit:"Your Current Credit",
                 text_send_file_page_total_credit:"Total Service",




                 text_send_file_page_total_credit_title:"Credit Usage Rate",
                 text_send_file_page_total_credit_info:"You Receive Services",





                 text_file_archives_page_bradcom_navi_title:"Files",
                 text_file_archives_page_bradcom_navi_pagename:"File Archives",
                 text_file_archives_page_bradcom_navi_list:"Arhives List",


                 text_sended_file_page_bradcom_navi_title:"Files",
                 text_sended_file_page_bradcom_navi_pagename:"Sended Files",
                 text_sended_file_page_bradcom_navi_list:"Files List",

                 text_incoming_file_page_bradcom_navi_title:"FILES",
                 text_incoming_file_archives_page_bradcom_navi_pagename:"Incoming Files",
                 text_incoming_file_archives_page_bradcom_navi_list:"Files List",





                /* Send File Form Step2 ( Send File Page) */

                 text_send_file_form_step2_vehicle_title:'Vehicle',
                 text_send_file_form_step2_vehicle:'Vehicle',
                 text_send_file_form_step2_brand_select:'Brand Select',
                 text_send_file_form_step2_brand:'Brand',
                 text_send_file_form_step2_model_select:'Model Select',
                 text_send_file_form_step2_model:'Model',
                 text_send_file_form_step2_year_select:'Year Select',
                 text_send_file_form_step2_year:'Year',
                 text_send_file_form_step2_engine_type:'Engine Type',
                 text_send_file_form_step2_engine:'Engine',
                 text_send_file_form_step2_gearbox_type:'Gear Box Type',
                 text_send_file_form_step2_gearbox:'Gear Box',
                 

                  /* Send File Form Step3( Send File Page) */

                  text_send_file_form_step3_reading_method_select:'Reading Method',
                  text_send_file_form_step3_reading_type:'Reading Type',
                  text_send_file_form_step3_milage:'Milage',
                  text_send_file_form_step3_licence_plate:'Licance Plate',
                  text_send_file_form_step3_vin_number:'Vin Number',
                  text_send_file_form_step3_dtc_list:'DTC List (Write separated by commas)',
                  text_send_file_form_step3_note:'Leave a note!',

                  text_send_file_form_step3_dtc_list_check:'DTC List',
                  text_send_file_form_step3_note_check:'Note',




                /* Send File Upload ( Send File Component) */


                  text_file_uplpad_labelIdle:'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>',
                  text_file_uplpad_labelFileLoading:'Loading',
                  text_file_uplpad_labelFileProcessing:'Uploading',
                  text_file_uplpad_labelFileProcessingComplete:'Upload complete',
                  text_file_uplpad_labelFileProcessingAborted:'Upload cancelled',
                  text_file_uplpad_labelTapToUndo:'tap to undo',
                  text_file_uplpad_labelTapToCancel:'tap to cancel',
                  text_file_uplpad_labelTapToRetry:'tap to retry',
                  text_file_uplpad_labelButtonRetryItemLoad:'Retry',
                  text_file_uplpad_labelButtonAbortItemProcessing:'Cancel',
                  text_file_uplpad_labelButtonUndoItemProcessing:'Undo',
                  text_file_uplpad_labelButtonRetryItemProcessing:'Retry',
                  text_file_uplpad_labelButtonProcessItem:'Upload',




                 /* Create Credit Package */ 

                 text_credit_page_bradcom_navi_title:"Credit",
                 text_credit_page_bradcom_navi_pagename:"Credit Packages",
                 text_credit_page_bradcom_navi_formname:"Credit Package Transactions ",

                 text_credit_package_credit_name:"Credit",
                 text_credit_package_buy_package:"Add to cart",
                
        
                 text_credit_package_create_from_input_credit_price:"Price",
                 text_credit_package_create_from_input_credit_discount:"Discount(%)",
                 text_credit_package_create_current_discount:"Current Disocunt Rate",
               


                 text_credit_package_notification_basket_add_success:"Credit Package Added Cart",
                 text_credit_package_notification_basket_change_quantity:"Credit Package Quantity Changed",
                 text_credit_package_notification_basket_delete:"Credit Package Delete To Cart",




                /* Navbar Novtification Cart */ 

                text_cart_notification_checkout:"Checkout",
                text_cart_notification_items:"Items",
                text_cart_notification_empty_cart:"Your Cart Is Empty",
                text_cart_notification_in_your_cart:"In Yor Cart",



                /* Checkout Page */ 


                text_checkout_page_bradcom_navi_title:"Credit",
                text_checkout_page_bradcom_navi_pagename:"Checkout",
                text_checkout_page_bradcom_navi_formname:"Payment",

                text_checkout_items_in_stock:"In Stock",
                text_checkout_items_quantity:"Quantity",
                text_checkout_items_current_discount:"Current Discount",
                text_checkout_items_have_discount:"have discount",
                text_checkout_items_delete:"Remove",
                text_checkout_items_wishlist:"Wishlist",


                text_checkout_left_colum_payment_options:"Payment Options",
                text_checkout_left_colum_payment_description:"Please select one of the payment methods below and proceed.",
                text_checkout_left_colum_type_credit_card:"Credit Card / Debit / ATM Card",
                text_checkout_left_colum_type_bank:"Bank Transfer",
                text_checkout_left_colum_type_memory_cards:"Your Memory Cards",
               

                text_checkout_card_name:"YOUR NAME HERE",
                text_checkout_card_date:"Valid thru",

                text_checkout_card_number:"Card Number",
                text_checkout_card_owner:"Name Surname",


                text_checkout_right_sidebar_title:"Your Credit Packages Info",
                text_checkout_right_sidebar_subtitle:"Price Info",
                text_checkout_right_sidebar_total:"Total :",
                text_checkout_right_sidebar_discount:"Discount :",
                text_checkout_right_sidebar_tax:"Tax :",
                text_checkout_right_sidebar_globaltotal:"Global Total",
                text_checkout_right_sidebar_next_button:"Next",
                text_checkout_right_sidebar_step_two_payment_button:"Payment",


                
                text_page_support_create_support:"Create Ticket",
                text_page_support_inbox:"Inbox",
                text_page_support_sent:"Send",
                text_page_support_draft:"Draft",
                text_page_support_trash:"Trash",
                text_page_support_Standart:"Standart",
                text_page_support_Normaly:"Important",
                text_page_support_Emegency:"Emergency",
                text_page_support_Private:"Private",

                text_page_support_select_all:"Select All",
                text_page_support_search_bar:"Search",
                text_page_support_labels:"Labels",
                
                text_page_support_form_title:"Create New Ticket",
                text_page_support_form_users:"Resellers",
                text_page_support_form_subject:"Subject",
                text_page_support_form_attacment:"ATTACHEMENTS",
                text_page_support_form_item_not_found:" This folder empty ",
                text_page_support_form_Answer:"Answer",


                /* Credit Order Page */ 

                text_credit_order_page_bradcom_navi_title:"Orders",
                text_credit_order_page_bradcom_navi_pagename:"Orders Settings",
                text_credit_order_page_bradcom_navi_endName:"Order List",
                
                text_credit_order_page_card_title:"Order List",

                text_credit_order_page_user_table_order_no:"Order No",
                text_credit_order_page_user_table_name:"Name Surname",
                text_credit_order_page_user_table_order_quantity:"Order Quantity",
                text_credit_order_page_user_table_total_credit:"Total Credit",
                text_credit_order_page_user_table_global_total:"Global Total",
                text_credit_order_page_user_table_date:"Order Date",
                text_credit_order_page_user_table_status:"Status",
                text_credit_order_page_user_table_setting:"Settings",

                text_credit_order_table_order_status_finished:"SUCCESS",
                text_credit_order_table_order_status_canceled:"CANCELED",
                text_credit_order_table_order_status_pending:"PENDING",




               /* Reselelr Invoice Page */ 
               
               text_reseller_order_invoice_bradcom_navi_title:"Reseller",
               text_reseller_order_invoice_bradcom_navi_pagename:"Orders",
               text_reseller_order_invoice_bradcom_navi_formname:"Invoice",

                text_reseller_order_invoice_mail:"Send Mail",
                text_reseller_order_invoice_print:"Print",
                text_reseller_order_invoice_approve:"Approve",
                text_reseller_order_invoice_invoice:"Invoice",
                text_reseller_order_invoice_invoice_no:"Invoice No",
                text_reseller_order_invoice_invoice_date:"Invoice Date",

                text_reseller_order_invoice_package_name:"Credit Package Name",
                text_reseller_order_invoice_credit:"Credit",
                text_reseller_order_invoice_quantity:"Quantity",
                text_reseller_order_invoice_price:"Price",
                text_reseller_order_invoice_total:"Total",
                text_reseller_order_invoice_suptotal:"SupTotal",
                text_reseller_order_invoice_discount:"Discount",
                text_reseller_order_invoice_global_total:"Global Total",


                text_reseller_order_invoice_footer_description:"Transfer the amounts to the business amount below. Please include invoice number on your check.",
                text_reseller_order_invoice_bank_name:"BANK",
                text_reseller_order_invoice_bank_account_name:"ACCOUNT NAME",
                text_reseller_order_invoice_bank_account_code:"ACCOUNT CODE",
                text_reseller_order_invoice_bank_iban:"IBAN",
                text_reseller_order_invoice_bank_branch_no:"BRANCH NO",
                text_reseller_order_invoice_bank_swift_code:"SWIFT CODE",
                text_reseller_order_invoice_bank_description:"BANK DESCRIPTION",

                
    
     

                text_checkout_model_step_one_title: "Do you want to complete the order ?",
                text_checkout_model_step_two_title: "You cannot cancel after completing the order. ",
                text_checkout_model2_step_confirm_title: "Your order has been created",
                text_checkout_model2_step_confirm_description: "Your order will be confirmed as soon as possible. ",
                text_checkout_model2_step_cancel_title: "Stopped",
                text_checkout_model2_step_cancel_description: "We did not take any action on your order.  ",




                text_checkout_finished_one:"CONGRATULATIONS",
                text_checkout_finished_two:"YOUR ORDER NUMBER HAS BEEN CREATED",
                text_checkout_finished_three:"You can check the status of your credit orders on the orders page.",
 
                

                text_file_no_send_checker_page_title: "One Last Check Before Posting.",
                text_file_no_send_checker_page_car_one: "Vehicle Info 1",
                text_file_no_send_checker_page_car_two: "Vehicle Info 2",
                text_file_no_send_checker_page_category_col: "Category",
                text_file_no_send_checker_page_selected_col: "Selected",
                text_file_no_send_checker_page_place_col: "Filled Field",
                text_file_no_send_checker_page_stated_col: "Specified",
                text_file_no_send_checker_page_fle_note: "The file you uploaded has been renamed.",
                text_file_no_send_checker_page_file_size: "File Size",
                text_file_no_send_checker_page_download_link: "Download Link",
                text_file_no_send_checker_page_download: "Download Link",




                }
              });
      
        };

    }


    case "tr" : {

      localStorage.removeItem("dil")
      localStorage.setItem("dil", "tr")
        return  dispatch => {
            dispatch({
                type:actionTypes.LANGUAGE_TR,
                playload:{

                    /* Buttons */

        
                    button_save:"Kaydet",
                    button_sent:"Gönder",
                    button_reset:"Temizle",
                    button_update:"Güncelle",
                    button_delete:"Sil",
                    button_cancel:"İptal",
                    button_no_cancel:"İptal Et",
                    button_yes_send:"Tamam, Gönder",
                    button_confirm:"Evet, istiyorum",
                    button_home:"Anasayfa",
                    button_order_page:"Sipariş Sayfası",
                    button_move_to_archive:"Arşive Taşı",

                    navbar_menu_edit_profil:"Profil Ayarları",
                    navbar_menu_logout:"Çıkış",
                    navbar_menu_Available:"Müsait",


                    button_settings:"Ayarlar",
                    button_download:"İndr",
                    button_download_file:"Dosya'yı İndir",
                    button_details:"Detaylar",
                    button_cancel_action:"İşlemi İptal Et",

                    

                
    

                   /* Files  Global*/
                  
                   text_files_file_no:"Dosya No",

                   text_files_details_services:"Hizmetler",
                   text_files_details_file_upload:"Dosya Yükle",

                    /* Files */
    
                    menu_text_dashboard:"Gösterge Paneli",
                    menu_text_incoming_files:"Gelen Dosyalar",
                    menu_text_outgoing_files:"Gönderilen Dosyalar",
                    menu_text_send_file:"Dosya Gönder",
                    menu_text_special_outgoing_files:"Özel Dosya Gönder",
                    menu_text_files_statistics:"Dosya İstatistikleri",
                    menu_text_arsives:"Arşiv",
                
                    /* Customer */
    
                    menu_text_add_reseller:"Bayi Ekle",
     
                   
                    /* Credit */
    
                    menu_text_credit_package:"Kredi Paketleri",
                    menu_text_credit_order:"Kredi Siparişleri",
                   
    
    
                    /* Services */
    
    
                    /* CHIPTUNING */
    
                  
    
    
                    /* Settings */
    
                    menu_text_support:"Destek",
    
    
    
                    /* Menü Title */
    
                    menu_text_title_file:"DOSYA YÖNETİMİ",
                
                    menu_text_title_credit:"KREDİ",
              
                    menu_text_title_support:"DESTEK",

                   /* Login Page*/

                   login_page_text_title :"Panel Giriş",
                   login_page_text_welcome :"Tekrar hoş geldiniz, lütfen hesabınıza giriş yapın.",
                   login_page_text_email_placeholder :"E-Posta adresiniz",
                   login_page_text_password_placeholder :"Şifreniz",
                   login_page_text_remmember :"Beni Hatırla",
                   login_page_text_registered_button :"Kayıt Ol",
                   login_page_text_complete_button :"Tamam Hazırım",
                   login_page_text_login_button :"Giriş",
                   login_page_text_loading_button :"Yükleniyor...",


                   /* Register Page*/

                   add_register_page_bradcom_form_usercode :"Bayi ID",
                   add_register_page_bradcom_form_firstname :"İsim",
                   add_register_page_bradcom_form_lastname :"Soyisim",
                   add_register_page_bradcom_form_email :"E-Posta",
                   add_register_page_bradcom_form_password :"Şifre",
                   add_register_page_bradcom_form_phone :"Telefon",
                   add_register_page_bradcom_form_company :"Şirket Adı",
                   add_register_page_bradcom_form_address :"Adres",
                   add_register_page_bradcom_form_country :"Ülke",
                   add_register_page_bradcom_form_city :"Şehir",

                   regsiter_page_text_h1_title:" Kayıt Formu",
                   regsiter_page_text_description:"Sisteme kayıt olmak için geçerli bir BAYİ ID' si gereklidir.Eğer bir bayi ile çalışıyorsanız,çalıştığınız bayinizden  link bağlantısı  veya bayi kodu talep edin.",
                   regsiter_page_text_description_ok:"Alttaki formu doldurarak sisteme kaydınızı tamamlayabilirsiniz. Sonra login sayfasına gidip giriş yapabilirsiniz.",
                   register_page_text_register_success:"Kaydınız tamamlandı, login sayfasına yönlendiriliyorsunuz...",
                   regsiter_page_text_regsiter_reseller_success_info :"Formu doldurduktan sonra sisteme giriş yapabilirsiniz",
                   regsiter_login_page_text_regsiter_reseller_success_warning :"Bayiden aldığınız bu Link hatalıdır Lütfen bayi ile iletişime geçin.",

                    
                   /* Reseller Page (Add Reseller Page) */


                   add_reseller_page_bradcom_navi_title :"Bayi Yönetimi",
                   add_reseller_page_bradcom_navi_pagename :"Bayi Ekle",
                   add_reseller_page_bradcom_navi_formname :" Bayi Ekleme Formu",
                   add_reseller_page_bradcom_form_firstname :"İsim",
                   add_reseller_page_bradcom_form_lastname :"Soyisim",
                   add_reseller_page_bradcom_form_email :"E-Posta",
                   add_reseller_page_bradcom_form_password :"Şifre",
                   add_reseller_page_bradcom_form_phone :"Telefon",
                   add_reseller_page_bradcom_form_company :"Şirket Adı",
                   add_reseller_page_bradcom_form_address :"Adres",
                   add_reseller_page_bradcom_form_country :"Ülke",
                   add_reseller_page_bradcom_form_city :"Şehir",




                   text_profil_old_password:"Eski şifre",
                   text_profil_new_password:"Yeni şifre ",
                   text_profil_confirm_pasword:"Yeni şifre tekrar",



                /* Files Page ( Send File Page) */

                text_send_file_page_bradcom_navi_title:"Dosya Yönetimi",
                text_send_file_page_bradcom_navi_pagename:"Dosya Gönder",
                text_send_file_page_bradcom_navi_formname:"Dosya Gönderme Formu",


                 text_send_file_page_prev:"Geri",
                 text_send_file_page_next:"Devam",
                 text_send_file_page_button_okay:"Hizmeti Oluştur",

                 text_send_file_page_form_title:"Dosya Gönderme Formu",
                 text_send_file_page_service_type:"Hizmet Seçiniz",
                 text_send_file_page_service_stepone_info_box_title:"Hizmet Ekstra Bilgi",
                 text_send_file_page_service_stepone_info_box_description:"Yukarıda şaçmiş olduğunuz her hizmetin kendine ait bir kredi tutarı vardır. Şuan mevcut olan kredinizi ve almak istediğiniz hizmetin kredi tutarını sağ taraftan kontrol edebilirsiniz.",
                 text_send_file_page_credit_total_title:"Kredi Hesaplama",
                 text_send_file_page_current_credit:"Mevcut Krediniz",
                 text_send_file_page_total_credit:"Hiztmetlerin Toplamı",



                 text_send_file_page_total_credit_title:"Kredi Kullanım Oranı",
                 text_send_file_page_total_credit_info:"Hizmetleri Alıyorsunuz",




                 text_file_archives_page_bradcom_navi_title:"Dosya Yönetimi",
                 text_file_archives_page_bradcom_navi_pagename:"Arşivler",
                 text_file_archives_page_bradcom_navi_list:"Arşiv Listesi",


                 text_sended_file_page_bradcom_navi_title:"Dosya Yönetimi",
                 text_sended_file_page_bradcom_navi_pagename:"Gönderilmiş Dosyalar",
                 text_sended_file_page_bradcom_navi_list:"Dosya Listesi",

                 text_incoming_file_page_bradcom_navi_title:"Dosya Yönetimi",
                 text_incoming_file_archives_page_bradcom_navi_pagename:"Gelen Dosyalar",
                 text_incoming_file_archives_page_bradcom_navi_list:"Dosya Listesi",



                /* Send File Form Step2 ( Send File Page) */

                 text_send_file_form_step2_vehicle_title:'Taşıt Tipi',
                 text_send_file_form_step2_vehicle:'Taşıt',
                 text_send_file_form_step2_brand_select:'Marka Şeç',
                 text_send_file_form_step2_brand:'Marka',
                 text_send_file_form_step2_model_select:'Model Seç',
                 text_send_file_form_step2_model:'Model',
                 text_send_file_form_step2_year_select:'Yıl Seç',
                 text_send_file_form_step2_year:'Yıl',
                 text_send_file_form_step2_engine_type:'Motor Türü',
                 text_send_file_form_step2_engine:'Motor',
                 text_send_file_form_step2_gearbox_type:'Vites Türü',
                 text_send_file_form_step2_gearbox:'Vites',
                 text_send_file_form_step3_:'',

                  /* Send File Form Step3( Send File Page) */

                  text_send_file_form_step3_reading_method_select:'Yazma Türü Seçiniz',
                  text_send_file_form_step3_reading_type:'Yazma Türü',
                  text_send_file_form_step3_milage:'Kilometre',
                  text_send_file_form_step3_licence_plate:'Plaka',
                  text_send_file_form_step3_vin_number:'Şasi No',
                  text_send_file_form_step3_dtc_list:'DTC Liste (Virgül ile ayırarak yazınız)',
                  text_send_file_form_step3_note:'Bir not bırakın!',

                  text_send_file_form_step3_dtc_list_check:'DTC Liste',
                  text_send_file_form_step3_note_check:'Notunuz',

                
                  /* Send File Upload ( Send File Component) */

             

                  text_file_uplpad_labelIdle:'Dosya yı tutarak buraya bırakabilirsiniz veya <span class="filepond--label-action"> Bilgisayardan Seç </span>',
                  text_file_uplpad_labelFileLoading:'Bekleyin',
                  text_file_uplpad_labelFileProcessing:'Dosya yükleniyor lütfen bekleyin.',
                  text_file_uplpad_labelFileProcessingComplete:'Yükleme tamamlandı.',
                  text_file_uplpad_labelFileProcessingAborted:'Yükleme iptal edildi.',
                  text_file_uplpad_labelTapToUndo:'Yüklemeyi geri al!',
                  text_file_uplpad_labelTapToCancel:'İptal et',
                  text_file_uplpad_labelTapToRetry:'Tekrar denemek için tıkla ',
                  text_file_uplpad_labelButtonRetryItemLoad:'Yeniden dene',
                  text_file_uplpad_labelButtonAbortItemProcessing:'İptal et',
                  text_file_uplpad_labelButtonUndoItemProcessing:'Geri al',
                  text_file_uplpad_labelButtonRetryItemProcessing:'Yeniden dene',
                  text_file_uplpad_labelButtonProcessItem:'Yükle',



                 /* Create Credit Package */ 
                 text_credit_page_bradcom_navi_title:"Kredi ",
                 text_credit_page_bradcom_navi_pagename:"Kredi Paketleri",
                 text_credit_page_bradcom_navi_formname:"Kredi Paket İşlemleri",

                 text_credit_package_credit_name:"Kredi",
                 text_credit_package_buy_package:"Sepete Ekle",
                
                 text_credit_package_create_from_input_credit_quantity:"Kredi Miktarı",
                 text_credit_package_create_from_input_credit_price:"Fiyatı",
                 text_credit_package_create_from_input_credit_discount:"İndirim(%)",
                 text_credit_package_create_current_discount:"Mevcut İndirim Oranı",



                 text_credit_package_notification_basket_add_success:"Ürün Sepete Eklendi",
                 text_credit_package_notification_basket_change_quantity:"Ürün Adeti Değiştirildi",
                 text_credit_package_notification_basket_delete:"Ürün Sepetten Kaldırıldı",



                /* Navbar Novtification Cart */ 

                text_cart_notification_checkout:"Alışverişi Tamamla",
                text_cart_notification_items:"Kredi Paketi",
                text_cart_notification_empty_cart:"Sepetinizde Ürün Mevcut Değil",
                text_cart_notification_in_your_cart:"Sepette Mevcut",

 

                /* Checkout Page */ 


                text_checkout_page_bradcom_navi_title:"Credit",
                text_checkout_page_bradcom_navi_pagename:"Checkout",
                text_checkout_page_bradcom_navi_formname:"Payment",

                text_checkout_items_in_stock:"Stokda Var",
                text_checkout_items_quantity:"Miktar",
                text_checkout_items_current_discount:"Mevcut İndirim",
                text_checkout_items_have_discount:"indirim vardır",
                text_checkout_items_delete:"Paketi Sil",
                text_checkout_items_wishlist:"İstek Listesi",


                text_checkout_left_colum_payment_options:"Ödeme Seçenekleri",
                text_checkout_left_colum_payment_description:"Lütfen alttaki ödeme yöntemlerinden birini seçin ve işleme devam edin.",
                text_checkout_left_colum_type_credit_card:"Kredi Kartı / Debit / ATM Kart",
                text_checkout_left_colum_type_bank:"Havale/EFT ile Ödeme",
                text_checkout_left_colum_type_memory_cards:"Kayıtlı Kartları Kullan",

                text_checkout_card_name:"KART SAHİBİ",
                text_checkout_card_date:"Tarih",
                text_checkout_card_number:"Kart Numarası",
                text_checkout_card_owner:"Ad Soyad",
              


                text_checkout_right_sidebar_title:"Kredi Paketletinizin Tutarı",
                text_checkout_right_sidebar_subtitle:"Fiyat Bilgisi",
                text_checkout_right_sidebar_total:"Toplam :",
                text_checkout_right_sidebar_discount:"İndirim :",
                text_checkout_right_sidebar_tax:"KDV :",
                text_checkout_right_sidebar_globaltotal:"Genel Toplan",
                text_checkout_right_sidebar_next_button:"Devam Et",
                text_checkout_right_sidebar_step_two_payment_button:"Hemen Öde",

                text_page_support_create_support:"Destek Oluştur",
                text_page_support_inbox:"Gelen",
                text_page_support_sent:"Giden",
                text_page_support_draft:"Taslak",
                text_page_support_trash:"Çöp Kutusu",
                text_page_support_Standart:"Standart",
                text_page_support_Normaly:"Önemli",
                text_page_support_Emegency:"Acil",
                text_page_support_Private:"Özel",

                text_page_support_select_all:"Tümünü Seç",
                text_page_support_search_bar:"Arama",
                text_page_support_labels:"Etiketler",
                
                text_page_support_form_title:"Yeni Destek Oluştur",
                text_page_support_form_users:"Kullanıcılar",
                text_page_support_form_subject:"Konu",
                text_page_support_form_attacment:"Ekler",
                text_page_support_form_item_not_found:" Bu Dosyada bir talep yok",
                text_page_support_form_Answer:"Cevap",



                /* Credit Order Page */ 



              text_credit_order_page_bradcom_navi_title:"Siparişler",
              text_credit_order_page_bradcom_navi_pagename:"Sipariş İşlemleri",
              text_credit_order_page_bradcom_navi_endName:"Sipariş Listesi",
              
              text_credit_order_page_card_title:"Sipariş Listesi",

              text_credit_order_page_user_table_order_no:"Sipariş No",
              text_credit_order_page_user_table_name:"Ad Soyad",
              text_credit_order_page_user_table_order_quantity:"Sipariş Adeti",
              text_credit_order_age_user_table_total_credit:"Toplam Kredi",
              text_credit_order_page_user_table_global_total:"Toplam Tutar",
              text_credit_order_page_user_table_date:"Sipariş Tarihi",
              text_credit_order_page_user_table_status:"Durum",
              text_credit_order_page_user_table_setting:"Ayarlar",

              text_credit_order_table_order_status_finished:"TAMAMLANDI",
              text_credit_order_table_order_status_canceled:"İPTAL EDİLDİ",
              text_credit_order_table_order_status_pending:"BEKLİYOR",



               
               /* Reselelr Invoice Page */ 
               
               text_reseller_order_invoice_bradcom_navi_title:"Bayiler",
               text_reseller_order_invoice_bradcom_navi_pagename:"Siparişler",
               text_reseller_order_invoice_bradcom_navi_formname:"Fatura",

               text_reseller_order_invoice_mail:"Mail Gönder",
               text_reseller_order_invoice_print:"Yazdır",
               text_reseller_order_invoice_approve:"Onayla",
               text_reseller_order_invoice_invoice:"Fatura",
               text_reseller_order_invoice_invoice_no:"Fatura No",
               text_reseller_order_invoice_invoice_date:"Fatura Tarih",

               text_reseller_order_invoice_package_name:"Kredi Paket Adı",
               text_reseller_order_invoice_credit:"Kredi",
               text_reseller_order_invoice_quantity:"Adet",
               text_reseller_order_invoice_price:"Fiyat",
               text_reseller_order_invoice_total:"Toplam",
               text_reseller_order_invoice_suptotal:"TOPLAM",
               text_reseller_order_invoice_discount:"İNDİRİM",
               text_reseller_order_invoice_global_total:"GENEL TOPLAM",


               text_reseller_order_invoice_footer_description:"Yukarda belirtilen tutarı altta belirtilen banka hesabına gönderebilirsiniz. Lütfen fatura no'sunu transfer açıklamasına yazın.",
               text_reseller_order_invoice_bank_name:"Banka",
               text_reseller_order_invoice_bank_account_name:"Müşteri No",
               text_reseller_order_invoice_bank_account_code:"Hesap No",
               text_reseller_order_invoice_bank_iban:"IBAN",
               text_reseller_order_invoice_bank_branch_no:"Şube Kodu",
               text_reseller_order_invoice_bank_swift_code:"Swfit Kodu",
               text_reseller_order_invoice_bank_description:"Açıklama",
                 



               text_checkout_model_step_one_title: "Siparişi tamamlamak istiyormusunuz ?",
               text_checkout_model_step_two_title: "Siparişi tamamladıktan sonra iptal edemezsiniz.",
               text_checkout_model2_step_confirm_title: "Siparişiniş oluşturuldu?",
               text_checkout_model2_step_confirm_description: "Siparişiniz en kısa sürede onaylanacaktır.",
               text_checkout_model2_step_cancel_title: "Durduruldu",
               text_checkout_model2_step_cancel_description: "Siparişiniz ile alakalı bir işlem yapmadık.",
     

               
               text_checkout_finished_one:"TEBRİKLER",
               text_checkout_finished_two:"NUMARALI SİPARİŞİNİZ OLUŞTURULDU",
               text_checkout_finished_three:"Kredi siparişlerinizin durumunu siparişler sayfasından kontrol edebilirsiniz.",

               text_file_no_send_checker_page_title:"Göndermeden Önce Son Bir Kontrol Edin.",
               text_file_no_send_checker_page_car_one:"Taşıt Bilgisi 1",
               text_file_no_send_checker_page_car_two:"Taşıt Bilgisi 2",
               text_file_no_send_checker_page_category_col:"Kategori",
               text_file_no_send_checker_page_selected_col:"Seçilen",
               text_file_no_send_checker_page_place_col:"Doldurulan Alan",
               text_file_no_send_checker_page_stated_col:"Belirtilen",
               text_file_no_send_checker_page_fle_note:"Yüklemiş oluduğunuz dosya yeniden adlandırılmıştır.",
               text_file_no_send_checker_page_file_size:"Dosya Boyutu",
               text_file_no_send_checker_page_download_link:"İndirme Bağlantısı",
               text_file_no_send_checker_page_download:"İndirme Bağlantısı",


                }
              });
      
        };
    }

     default : {

      localStorage.removeItem("dil")
      localStorage.setItem("dil", "tr")

        return  dispatch => {
            dispatch({
                type:actionTypes.LANGUAGE_TR,
                playload:{

                    /* Buttons */

        
                    button_save:"Kaydet",
                    button_sent:"Gönder",
                    button_reset:"Temizle",
                    button_update:"Güncelle",
                    button_delete:"Sil",
                    button_cancel:"İptal",
                    button_no_cancel:"İptal Et",
                    button_yes_send:"Tamam, Gönder",
                    button_confirm:"Evet, istiyorum",
                    button_home:"Anasayfa",
                    button_order_page:"Sipariş Sayfası",
                    button_move_to_archive:"Arşive Taşı",

                    navbar_menu_edit_profil:"Profil Ayarları",
                    navbar_menu_logout:"Çıkış",
                    navbar_menu_Available:"Müsait",


                    button_settings:"Ayarlar",
                    button_download:"İndr",
                    button_download_file:"Dosya'yı İndir",
                    button_details:"Detaylar",
                    button_cancel_action:"İşlemi İptal Et",

                
    

                   /* Files  Global*/
                  
                   text_files_file_no:"Dosya No",
                   text_files_details_services:"Hizmetler",
                   text_files_details_file_upload:"Dosya Yükle",


                    /* Files */
    
                    menu_text_dashboard:"Gösterge Paneli",
                    menu_text_incoming_files:"Gelen Dosyalar",
                    menu_text_outgoing_files:"Gönderilen Dosyalar",
                    menu_text_send_file:"Dosya Gönder",
                    menu_text_special_outgoing_files:"Özel Dosya Gönder",
                    menu_text_files_statistics:"Dosya İstatistikleri",
                    menu_text_arsives:"Arşiv",
                
                    /* Customer */
    
                    menu_text_add_reseller:"Bayi Ekle",
         
           
                   
                    /* Credit */
    
                    menu_text_credit_package:"Kredi Paketleri",
                    menu_text_credit_order:"Kredi Siparişleri",
                 
    
    
                    menu_text_support:"Destek",
    
    
    
                    /* Menü Title */
    
                    menu_text_title_file:"DOSYA YÖNETİMİ",
                
                    menu_text_title_credit:"KREDİ",
              
                    menu_text_title_support:"DESTEK",

    
            
                   /* Login Page*/

                   login_page_text_title :"Panel Giriş",
                   login_page_text_welcome :"Tekrar hoş geldiniz, lütfen hesabınıza giriş yapın.",
                   login_page_text_email_placeholder :"E-Posta adresiniz",
                   login_page_text_password_placeholder :"Şifreniz",
                   login_page_text_remmember :"Beni Hatırla",
                   login_page_text_registered_button :"Kayıt Ol",
                   login_page_text_login_button :"Giriş",
                   login_page_text_loading_button :"Yükleniyor...",
                   login_page_text_complete_button :"Tamam Hazırım",


                  /* Register Page*/

                  add_register_page_bradcom_form_usercode :"Bayi ID",
                  add_register_page_bradcom_form_firstname :"İsim",
                  add_register_page_bradcom_form_lastname :"Soyisim",
                  add_register_page_bradcom_form_email :"E-Posta",
                  add_register_page_bradcom_form_password :"Şifre",
                  add_register_page_bradcom_form_phone :"Telefon",
                  add_register_page_bradcom_form_company :"Şirket Adı",
                  add_register_page_bradcom_form_address :"Adres",
                  add_register_page_bradcom_form_country :"Ülke",
                  add_register_page_bradcom_form_city :"Şehir",

                   regsiter_page_text_h1_title:" Kayıt Formu",
                   regsiter_page_text_description:"Sisteme kayıt olmak için geçerli bir BAYİ ID' si gereklidir.Eğer bir bayi ile çalışıyorsanız,çalıştığınız bayinizden  link bağlantısı  veya bayi kodu talep edin.",
                   regsiter_page_text_description_ok:"Alttaki formu doldurarak sisteme kaydınızı tamamlayabilirsiniz. Sonra login sayfasına gidip giriş yapabilirsiniz.",
                   register_page_text_register_success:"Kaydınız tamamlandı, login sayfasına yönlendiriliyorsunuz...",
                   regsiter_page_text_regsiter_reseller_success_info :"Formu doldurduktan sonra sisteme giriş yapabilirsiniz",
                   regsiter_login_page_text_regsiter_reseller_success_warning :"Bayiden aldığınız bu Link hatalıdır Lütfen bayi ile iletişime geçin.",

                    
                   /* Reseller Page (Add Reseller Page) */


                   add_reseller_page_bradcom_navi_title :"Bayi Yönetimi",
                   add_reseller_page_bradcom_navi_pagename :"Bayi Ekle",
                   add_reseller_page_bradcom_navi_formname :" Bayi Ekleme Formu",
                   add_reseller_page_bradcom_form_firstname :"İsim",
                   add_reseller_page_bradcom_form_lastname :"Soyisim",
                   add_reseller_page_bradcom_form_email :"E-Posta",
                   add_reseller_page_bradcom_form_password :"Şifre",
                   add_reseller_page_bradcom_form_phone :"Telefon",
                   add_reseller_page_bradcom_form_company :"Şirket Adı",
                   add_reseller_page_bradcom_form_address :"Adres",
                   add_reseller_page_bradcom_form_country :"Ülke",
                   add_reseller_page_bradcom_form_city :"Şehir",
                   

                   text_profil_old_password:"Eski şifre",
                   text_profil_new_password:"Yeni şifre ",
                   text_profil_confirm_pasword:"Yeni şifre tekrar",

                 /* Files Page ( Send File Page) */
             

                text_send_file_page_bradcom_navi_title:"Dosya Yönetimi",
                text_send_file_page_bradcom_navi_pagename:"Dosya Gönder",
                text_send_file_page_bradcom_navi_formname:"Dosya Gönderme Formu",


                 text_send_file_page_prev:"Geri",
                 text_send_file_page_next:"Devam",
                 text_send_file_page_button_okay:"Hizmeti Oluştur",

                 text_send_file_page_form_title:"Dosya Gönderme Formu",
                 text_send_file_page_service_type:"Hizmet Seçiniz",
                 text_send_file_page_service_stepone_info_box_title:"Hizmet Ekstra Bilgi",
                 text_send_file_page_service_stepone_info_box_description:"Yukarıda şaçmiş olduğunuz her hizmetin kendine ait bir kredi tutarı vardır. Şuan mevcut olan kredinizi ve almak istediğiniz hizmetin kredi tutarını sağ taraftan kontrol edebilirsiniz.",
                 text_send_file_page_credit_total_title:"Kredi Hesaplama",
                 text_send_file_page_current_credit:"Mevcut Krediniz",
                 text_send_file_page_total_credit:"Hiztmetlerin Toplamı",

                 text_send_file_page_total_credit_title:"Kredi Kullanım Oranı",
                 text_send_file_page_total_credit_info:"Hizmetleri Alıyorsunuz",




                 text_file_archives_page_bradcom_navi_title:"Dosya Yönetimi",
                 text_file_archives_page_bradcom_navi_pagename:"Arşivler",
                 text_file_archives_page_bradcom_navi_list:"Arşiv Listesi",


                 text_sended_file_page_bradcom_navi_title:"Dosya Yönetimi",
                 text_sended_file_page_bradcom_navi_pagename:"Gönderilmiş Dosyalar",
                 text_sended_file_page_bradcom_navi_list:"Dosya Listesi",

                 text_incoming_file_page_bradcom_navi_title:"Dosya Yönetimi",
                 text_incoming_file_archives_page_bradcom_navi_pagename:"Gelen Dosyalar",
                 text_incoming_file_archives_page_bradcom_navi_list:"Dosya Listesi",

                /* Send File Form Step2 ( Send File Page) */

                 text_send_file_form_step2_vehicle_title:'Taşıt Tipi',
                 text_send_file_form_step2_vehicle:'Taşıt',
                 text_send_file_form_step2_brand_select:'Marka Şeç',
                 text_send_file_form_step2_brand:'Marka',
                 text_send_file_form_step2_model_select:'Model Seç',
                 text_send_file_form_step2_model:'Model',
                 text_send_file_form_step2_year_select:'Yıl Seç',
                 text_send_file_form_step2_year:'Yıl',
                 text_send_file_form_step2_engine_type:'Motor Türü',
                 text_send_file_form_step2_engine:'Motor',
                 text_send_file_form_step2_gearbox_type:'Vites Türü',
                 text_send_file_form_step2_gearbox:'Vites',
                 text_send_file_form_step3_:'',

                  /* Send File Form Step3( Send File Page) */

                  text_send_file_form_step3_reading_method_select:'Yazma Türü Seçiniz',
                  text_send_file_form_step3_reading_type:'Yazma Türü',
                  text_send_file_form_step3_milage:'Kilometre',
                  text_send_file_form_step3_licence_plate:'Plaka',
                  text_send_file_form_step3_vin_number:'Şasi No',
                  text_send_file_form_step3_dtc_list:'DTC Liste (Virgül ile ayırarak yazınız)',
                  text_send_file_form_step3_note:'Bir not bırakın!',

                  text_send_file_form_step3_dtc_list_check:'DTC Liste',
                  text_send_file_form_step3_note_check:'Notunuz',

        

                 /* Create Credit Package */ 
                 text_credit_page_bradcom_navi_title:"Kredi ",
                 text_credit_page_bradcom_navi_pagename:"Kredi Paketleri",
                 text_credit_page_bradcom_navi_formname:"Kredi Paket İşlemleri",

                 text_credit_package_credit_name:"Kredi",
                 text_credit_package_buy_package:"Sepete Ekle",
                
                 text_credit_package_create_from_input_credit_quantity:"Kredi Miktarı",
                 text_credit_package_create_from_input_credit_price:"Fiyatı",
                 text_credit_package_create_from_input_credit_discount:"İndirim(%)",
                 text_credit_package_create_current_discount:"Mevcut İndirim Oranı",



                 text_credit_package_notification_basket_add_success:"Ürün Sepete Eklendi",
                 text_credit_package_notification_basket_change_quantity:"Ürün Adeti Değiştirildi",
                 text_credit_package_notification_basket_delete:"Ürün Sepetten Kaldırıldı",






                /* Navbar Novtification Cart */ 

                 text_cart_notification_checkout:"Alışverişi Tamamla",
                 text_cart_notification_items:"Kredi Paketi",
                 text_cart_notification_empty_cart:"Sepetinizde Ürün Mevcut Değil",
                 text_cart_notification_in_your_cart:"Sepette Mevcut",



                /* Checkout Page */ 


                text_checkout_page_bradcom_navi_title:"Credit",
                text_checkout_page_bradcom_navi_pagename:"Checkout",
                text_checkout_page_bradcom_navi_formname:"Payment",

                text_checkout_items_in_stock:"Stokda Var",
                text_checkout_items_quantity:"Miktar",
                text_checkout_items_current_discount:"Mevcut İndirim",
                text_checkout_items_have_discount:"indirim vardır",
                text_checkout_items_delete:"Paketi Sil",
                text_checkout_items_wishlist:"İstek Listesi",


                text_checkout_left_colum_payment_options:"Ödeme Seçenekleri",
                text_checkout_left_colum_payment_description:"Lütfen alttaki ödeme yöntemlerinden birini seçin ve işleme devam edin.",
                text_checkout_left_colum_type_credit_card:"Kredi Kartı / Debit / ATM Kart",
                text_checkout_left_colum_type_bank:"Havale/EFT ile Ödeme",
                text_checkout_left_colum_type_memory_cards:"Kayıtlı Kartları Kullan",
              
                text_checkout_card_name:"KART SAHİBİ",
                text_checkout_card_date:"Tarih",
                text_checkout_card_number:"Kart Numarası",
                text_checkout_card_owner:"Ad Soyad",

                text_checkout_right_sidebar_title:"Kredi Paketletinizin Tutarı",
                text_checkout_right_sidebar_subtitle:"Fiyat Bilgisi",
                text_checkout_right_sidebar_total:"Toplam :",
                text_checkout_right_sidebar_discount:"İndirim :",
                text_checkout_right_sidebar_tax:"KDV :",
                text_checkout_right_sidebar_globaltotal:"Genel Toplan",
                text_checkout_right_sidebar_next_button:"Devam Et",
                text_checkout_right_sidebar_step_two_payment_button:"Hemen Öde",

                text_page_support_create_support:"Destek Oluştur",
                text_page_support_inbox:"Gelen",
                text_page_support_sent:"Giden",
                text_page_support_draft:"Taslak",
                text_page_support_trash:"Çöp Kutusu",
                text_page_support_Standart:"Standart",
                text_page_support_Normaly:"Önemli",
                text_page_support_Emegency:"Acil",
                text_page_support_Private:"Özel",

                text_page_support_select_all:"Tümünü Seç",
                text_page_support_search_bar:"Arama",
                text_page_support_labels:"Etiketler",
                
                text_page_support_form_title:"Yeni Destek Oluştur",
                text_page_support_form_users:"Kullanıcılar",
                text_page_support_form_subject:"Konu",
                text_page_support_form_attacment:"Ekler",
                text_page_support_form_item_not_found:" bu dosyada bir talep yok",
                text_page_support_form_Answer:"Cevap",
                

                 /* Credit Order Page */ 



                 text_credit_order_page_bradcom_navi_title:"Siparişler",
                 text_credit_order_page_bradcom_navi_pagename:"Sipariş İşlemleri",
                 text_credit_order_page_bradcom_navi_endName:"Sipariş Listesi",
                 
                 text_credit_order_page_card_title:"Sipariş Listesi",

                text_credit_order_page_user_table_order_no:"Sipariş No",
                text_credit_order_page_user_table_name:"Ad Soyad",
                text_credit_order_page_user_table_order_quantity:"Sipariş Adeti",
                text_credit_order_age_user_table_total_credit:"Toplam Kredi",
                text_credit_order_page_user_table_global_total:"Toplam Tutar",
                text_credit_order_page_user_table_date:"Sipariş Tarihi",
                text_credit_order_page_user_table_status:"Durum",
                text_credit_order_page_user_table_setting:"Ayarlar",

                text_credit_order_table_order_status_finished:"TAMAMLANDI",
                text_credit_order_table_order_status_canceled:"İPTAL EDİLDİ",
                text_credit_order_table_order_status_pending:"BEKLİYOR",


               
               /* Reselelr Invoice Page */ 
               
               text_reseller_order_invoice_bradcom_navi_title:"Bayiler",
               text_reseller_order_invoice_bradcom_navi_pagename:"Siparişler",
               text_reseller_order_invoice_bradcom_navi_formname:"Fatura",

               text_reseller_order_invoice_mail:"Mail Gönder",
               text_reseller_order_invoice_print:"Yazdır",
               text_reseller_order_invoice_approve:"Onayla",
               text_reseller_order_invoice_invoice:"Fatura",
               text_reseller_order_invoice_invoice_no:"Fatura No",
               text_reseller_order_invoice_invoice_date:"Fatura Tarih",

               text_reseller_order_invoice_package_name:"Kredi Paket Adı",
               text_reseller_order_invoice_credit:"Kredi",
               text_reseller_order_invoice_quantity:"Adet",
               text_reseller_order_invoice_price:"Fiyat",
               text_reseller_order_invoice_total:"Toplam",
               text_reseller_order_invoice_suptotal:"TOPLAM",
               text_reseller_order_invoice_discount:"İNDİRİM",
               text_reseller_order_invoice_global_total:"GENEL TOPLAM",


               text_reseller_order_invoice_footer_description:"Yukarda belirtilen tutarı altta belirtilen banka hesabına gönderebilirsiniz. Lütfen fatura no'sunu transfer açıklamasına yazın.",
               text_reseller_order_invoice_bank_name:"Banka",
               text_reseller_order_invoice_bank_account_name:"Müşteri No",
               text_reseller_order_invoice_bank_account_code:"Hesap No",
               text_reseller_order_invoice_bank_iban:"IBAN",
               text_reseller_order_invoice_bank_branch_no:"Şube Kodu",
               text_reseller_order_invoice_bank_swift_code:"Swfit Kodu",
               text_reseller_order_invoice_bank_description:"Açıklama",


               text_checkout_model_step_one_title: "Siparişi tamamlamak istiyormusunuz ?",
               text_checkout_model_step_two_title: "Siparişi tamamladıktan sonra iptal edemezsiniz.",
               text_checkout_model2_step_confirm_title: "Siparişiniz oluşturuldu?",
               text_checkout_model2_step_confirm_description: "Siparişiniş en kısa sürede onaylanacaktır.",
               text_checkout_model2_step_cancel_title: "Durduruldu",
               text_checkout_model2_step_cancel_description: "Siparişiniz ile alakalı bir işlem yapmadık.",


               text_checkout_finished_one:"TEBRİKLER",
               text_checkout_finished_two:"NUMARALI SİPARİŞİNİZ OLUŞTURULDU",
               text_checkout_finished_three:"Kredi siparişlerinizin durumunu siparişler sayfasından kontrol edebilirsiniz.",



               text_file_no_send_checker_page_title:"Göndermeden Önce Son Bir Kontrol Edin.",
               text_file_no_send_checker_page_car_one:"Taşıt Bilgisi 1",
               text_file_no_send_checker_page_car_two:"Taşıt Bilgisi 2",
               text_file_no_send_checker_page_category_col:"Kategori",
               text_file_no_send_checker_page_selected_col:"Seçilen",
               text_file_no_send_checker_page_place_col:"Doldurulan Alan",
               text_file_no_send_checker_page_stated_col:"Belirtilen",
               text_file_no_send_checker_page_fle_note:"Yüklemiş oluduğunuz dosya yeniden adlandırılmıştır.",
               text_file_no_send_checker_page_file_size:"Dosya Boyutu",
               text_file_no_send_checker_page_download_link:"İndirme Bağlantısı",
               text_file_no_send_checker_page_download:"İndirme Bağlantısı",



                }
                
              });
      
        };

     }
    
  
 

}




  };









  /*
  
  
  export const languageEN = () => ({
    type:actionTypes.LANGUAGE_EN,
    playload:{
        menu_text_dashboard:"Dashboard",
        menu_text_first:"First Menu",
        menu_text_second:"Second Menu",
    }

})


export const languageTR = () => ({
    type:actionTypes.LANGUAGE_TR,
    playload:{
        menu_text_dashboard:"Anasayfa",
        menu_text_first:"Birinci Menü",
        menu_text_second:"İkinci Menü",
    }

})
  
  */